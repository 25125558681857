@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: board.scss
* description	: board CSS import
* author		: kurt
* date			: 2024.02.06
+ version		: v1.0
************************************************************************/
.board-door {
  @include flex;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  height: 200px;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  text-transform: uppercase;
}

.noti-content {
  position: relative;
  width: 100%;
  padding: 50px 0 50px;

  .board-tab {
    @include flex;
    margin: 0 0 60px 0;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      height: 50px;
      flex: 1;
      color: #bdbdbd;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      cursor: pointer;

      &.active {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        border-bottom: 2px solid #0569ff;
      }

      &:hover {
        color: #000;
      }
    }
  }

  .board-subtab {
    @include flex;
    margin: 0 0 24px 0;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      height: 50px;
      padding: 12px 16px;
      color: #bdbdbd;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;

      &.active {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        border-bottom: 2px solid #0569ff;
      }

      &:hover {
        color: #000;
      }
    }
  }
}

.tb-body {
  // border-radius: 5px 5px;
  // box-shadow: 0px 0px 1px 1px #edf2f7;
  // border-bottom: 1px solid #ddd;
  // background-color: #f8f8f8;
  border-radius: 8px;
  border: 1px solid #ddd;

  .tb-tit {
    @include flex;
    border-radius: 8px 8px 0 0;
    box-shadow: 0px -1px 0px 0px #ddd inset;
    background-color: #f8f8f8;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      padding: 12px 24px;
      // flex: 1;

      color: #000;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .tb-txt {
    @include flex;
    box-shadow: 0px -1px 0px 0px #ddd inset;
    cursor: pointer;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      // flex: 1;
      padding: 12px 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;

      > em {
        display: flex;
        padding: 0px 16px;
        color: #fff;
        align-items: center;
        border-radius: 100px;
      }
    }

    &:nth-last-child(1) {
      box-shadow: 0px 0px 0px 0px #edf2f7 inset;
    }

    &:hover {
      background-color: #e5e5e5;
    }
  }
}

.report-content {
  position: relative;
  width: 100%;
  padding: 50px 0 50px;

  .board-tab {
    @include flex;
    margin: 0 0 60px 0;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      height: 50px;
      flex: 1;
      color: #bdbdbd;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
      cursor: pointer;

      &.active {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        border-bottom: 2px solid #0569ff;
      }

      &:hover {
        color: #000;
      }
    }
  }

  .board-subtab {
    @include flex;
    margin: 0 0 24px 0;

    span {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      height: 50px;
      padding: 12px 16px;
      color: #bdbdbd;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;

      &.active {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        border-bottom: 2px solid #0569ff;
      }

      &:hover {
        color: #000;
      }
    }
  }

  .tb-body {
    .tb-txt {
      span {
        &:nth-child(2) {
          justify-content: flex-start;
        }
      }
    }
  }
}

.view-box {
  margin: 50px 0;
  border: 1px solid #ddd;

  .view-tit {
    padding: 20px 20px;
    color: #000;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .view-txt {
    padding: 20px 20px;
    color: #000;
    // text-align: left;
    // font-size: 18px;
    // font-style: normal;
    // font-weight: 300;
    // line-height: 24px;
    pre {
      white-space: pre-wrap;
      color: #000;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .view-inner-txt {
      white-space: pre-wrap;
      color: #000;
      text-align: left;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      .center {
        text-align: center;
      }
      .mt-20 {
        margin-top: 20px;
      }

      .mt-40 {
        margin-top: 40px;
      }
      .mb-10 {
        margin-bottom: 10px;
      }
      div.gray-box {
        border-radius: 8px;
        background-color: #eee;
        padding: 20px;
        font-weight: bold;
        div.blue-box {
          border-radius: 8px;
          margin-top: 20px;
          background-color: #007aff20;
          padding: 10px 20px;
          font-weight: normal;
          .box-header {
            font-weight: bold;
          }
        }
      }
      div.contact-box {
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 8px;
        background-color: #eee;
        padding: 20px;
        display: flex;
        flex-direction: column;
        .row {
          flex-direction: row;
          label {
            display: inline-block;
            width: 120px;
            font-weight: bold;
          }
          p {
            display: inline-block;
          }
        }
      }
      span.annotation {
        color: #da4c4c;
        font-weight: bold;
      }
      p {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
      }
      .blue {
        color: #1a4ca1;
      }
      .bold {
        font-weight: bold;
      }
      .left {
        text-align: left;
      }
      .normal {
        font-weight: normal;
        font-size: 0.8rem;
      }
      .board-view-end {
        margin-top: 40px;
        a {
          display: inline;
          color: #0563c1;
          &:hover {
            color: #007aff;
          }
        }
      }

      table.noti-table {
        border-color: #7f7f7f;
        font-size: 0.8rem;
        border-style: solid;
        border-width: 0;
        font-weight: normal;
        width: 100%;
        caption {
          display: table-caption;
          text-align: left;
          width: initial;
          height: inherit;
          visibility: visible;
          line-height: 1rem;
          font-size: 0.8rem;
          padding: 10px;
        }

        &.no-padding {
          th,
          td {
            padding: 0;
          }
        }
        &.center {
          text-align: center;
        }
        &.middle {
          th,
          td {
            vertical-align: middle;
          }
        }
        td,
        th {
          border: 1px solid #7f7f7f;
          padding: 10px;
        }

        thead {
          text-align: center;
          vertical-align: middle;
          background-color: #d9d9d9;
        }
        tbody {
          > tr {
            th {
              padding: 10px;
              background-color: #f2f2f2;
              text-align: center;
              vertical-align: middle;
            }
            td {
              vertical-align: top;
              padding: 10px;
              p {
                line-height: 1rem;
              }
            }
          }
        }
      }
      h2 {
        margin: 20px;
        text-align: center;
      }

      ul,
      ul > li {
        list-style: initial;
      }

      ul {
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;

        > ul {
          margin-block-start: 0;
        }
      }

      ol,
      ol > li {
        list-style: decimal;
      }

      ol {
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;

        &.no-list {
          list-style: none;

          > li {
            list-style: none;
          }
        }

        .bracket-number {
          counter-reset: item;

          li {
            display: block;

            &::before {
              content: '(' counter(item) ') ';
              counter-increment: item;
              width: 2em;
              margin-left: -1.5em;
              unicode-bidi: isolate;
              font-variant-numeric: tabular-nums;
              text-transform: none;
              text-indent: 0 !important;
              text-align: start !important;
              text-align-last: start !important;
            }
          }
        }
        .dot-number {
          counter-reset: item;

          li {
            display: block;

            &::before {
              content: counter(item) '. ';
              counter-increment: item;
              width: 2em;
              margin-left: -1.5em;
              unicode-bidi: isolate;
              font-variant-numeric: tabular-nums;
              text-transform: none;
              text-indent: 0 !important;
              text-align: start !important;
              text-align-last: start !important;
            }
          }
        }

        li {
          font-size: 0.9rem;

          &.h1 {
            font-weight: bold;
            font-size: 1.3rem;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }

          &.h2 {
            font-weight: bold;
            font-size: 1.1rem;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }

          &.h3 {
            font-weight: bold;
            margin-block-start: 2em;
            margin-block-end: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }
        }
      }

      ul {
        > li {
          font-size: 0.9rem;

          &.h1 {
            font-weight: bold;
            font-size: 1.3rem;
            margin-block-start: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }

          &.h2 {
            font-weight: bold;
            font-size: 1.1rem;
            margin-block-start: 1em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }

          &.h3 {
            font-weight: bold;
            margin-block-start: 2em;
            margin-inline-start: 0;
            margin-inline-end: 0;
            unicode-bidi: isolate;
          }
        }
      }
    }

    &.right {
      text-align: right;
    }
  }
}

.paging {
  display: flex;
  justify-content: center;
  padding: 20px 10px;

  li {
    a {
      display: flex;
      padding: 5px 5px;
    }

    &.selected {
      a {
        color: #000;
        font-weight: bold;
      }
    }
  }
}

// tablet css
@include tablet {
}

// mobile css
@include mobile {
  .board-door {
    height: 100px;
    font-size: 24px;
  }
  .tb-body {
    width: auto;
    margin: 0 10px;
    padding: 0 5px;

    .tb-tit,
    .tb-txt {
      span {
        padding: 10px 0;
        flex: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        line-height: 16px;

        &:nth-child(2) {
          padding: 10px 10px 10px;
          white-space: pre-wrap;
        }
      }
    }
  }
}
