@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: auto;
  box-sizing: border-box;
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
  background-color: var(--bg);
  color: var(--font-color);
  scroll-behavior: smooth;
}

ul,
ol,
li,
dl,
dt,
dd {
  list-style: none;
  text-align: left;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  border: none;
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  cursor: pointer;
}

// select {
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
// }

button,
input {
  overflow: visible;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

button,
select {
  text-transform: none;
}

address {
  text-decoration: none;
  font-style: normal;
  text-transform: capitalize;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  text-decoration: none;
  font-style: normal;
  outline: none;
  cursor: pointer;
  color: #000;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow-x: hidden;
  overflow-y: auto;
}

table {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  display: none;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  visibility: hidden;
}

.disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

em {
  font-style: normal;
}

[data-test-id] {
  margin: 0 !important;

  div {
    position: relative !important;
  }
}

#text {
  cursor: default;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* custom scrollbar */
::-webkit-scrollbar {
  //width: 15px;
  // cursor: pointer;
}

::-webkit-scrollbar-thumb {
  border: 1px solid #808080;
  background-color: #2f3542;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #808080;
  border-radius: 5px;
  box-shadow: inset 0px 0px 5px white;
}
