@charset "utf-8";
@import '@style/mixin';

.door {
  width: 100%;
  padding: 0;

  .door-title {
    margin: 150px 0 16px 0;
    color: #fff;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;
    text-align: center;
  }

  .door-text {
    color: #ccc;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-align: center;

    &.door-has-btn-false {
      margin-bottom: 150px !important;
    }
  }

  .door-btn-box {
    @include flex;
    justify-content: center;
    margin: 80px 0 156px 0;

    span,
    button,
    a {
      display: flex;
      width: 200px;
      padding: 14px 8px;
      justify-content: center;
      align-items: center;
      color: #222;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      border-radius: 8px;
      border: 1px solid #ddd;
      background-color: #fff;
      cursor: pointer;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      margin: 0 16px 0 0;

      &:nth-child(2) {
        color: #fff;
        border: 0;
        background: #0569ff;

        &:hover {
          background-color: #5493f2;
        }
      }

      &:nth-last-child(1) {
        margin: 0;
      }

      &:hover {
        background-color: #1d8de4;
        color: #fff;
        border-color: #007aff;
      }
    }
  }
}

@include tablet {
  .door {
    .door-title {
      font-size: 34px;
      line-height: 36px;
      margin: 111px auto 8px !important;
    }

    .door-text {
      &.door-has-btn-false {
        margin-bottom: 100px !important;
      }
    }
    .door-btn-box {
      margin: 40px 0;

      span,
      button,
      a {
        font-size: 14px;
        line-height: 22px;
        padding: 8px;
        width: 100px;
      }
    }
  }
}

@include mobile {
  .door {
    .door-title {
      font-size: 24px;
      line-height: 32px;
      margin: 70px auto 8px !important;
    }

    .door-text {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: -0.64px;
      line-height: 24px;
      margin: 0 auto 24px !important;
      text-align: center;

      &.door-has-btn-false {
        margin-bottom: 70px !important;
      }
    }
  }
}
