@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: layout.scss
* description	: layout CSS import
* author		: kurt
* date			: 2023.07.04
+ version		: v1.0
************************************************************************/

// parallax layout
.parallax-layout {
  position: relative;
  width: 100%;
  // overflow-y: auto;
  .go-up {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    border: 2px solid #25264a;
    box-shadow: 0 0 2px 2px #ffffff;
    background-color: #000;
    border-radius: 50%;
    z-index: 9;

    &:before,
    &:after {
      position: absolute;
      right: 27px;
      top: 13px;
      content: ' ';
      width: 3px;
      height: 20px;
      background-color: #fff;
      transition: 0.2s ease-in-out;
    }

    &:before {
      top: 13px;
      transform: rotate(45deg);
    }

    &:after {
      right: 15px;
      transform: rotate(-45deg);
    }

    cursor: pointer;
  }

  .container {
    position: relative;
    padding: 60px 0 0 0;

    .section-full {
      width: 100%;

      &.height100 {
        @include flex;
        @include align-items(center);
        @include justify-content(center);
        background-color: var(--bg);
        min-height: 100vh;
      }

      &.b-bg {
        background-color: #ddebff;
      }

      &.b-black-bg {
        background-color: #000;
      }

      &.authority-bg {
        @include background(url('../../../images/common/authority_bg.png'), 100%, 100%, 0, 0, no-repeat);
      }

      &.b-black-border-bg {
        box-shadow: 0 0 5px rgba(189, 189, 189, 0.5);
        background-color: #000;
      }

      &.section-full-sub-bg {
        @include flex;
        @include align-items(center);
        @include justify-content(center);
        height: 200px;
        @include background(url('../../../images/common/section_full_sub_bg.png'), 100%, 100%, 0, 0, no-repeat);
        color: #fff;

        p {
          color: #fff;
          text-align: center;
          font-size: 28px;
          font-style: normal;
          font-weight: 100;
          line-height: 40px;

          span {
            font-weight: 600;
          }
        }
      }
    }

    .section-part {
      position: relative;
      @include flex;
      width: var(--sectionWidth);
      margin: 0 auto;

      .part-tit {
        position: absolute;
        left: 70px;
        top: 30px;
        height: 22px;
        line-height: 22px;
        letter-spacing: 1px;
        font-size: 22px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        font-family: 'DM Serif Text';
        text-align: center;
      }

      &.row-column {
        flex-direction: column;
      }
    }

    .section-content {
      width: 100%;
      padding: 100px 0;

      .sub-logo {
        @include width-height(152px, 48px);
        @include background(url('../../../images/logo/logo.png'), 100%, 100%, 0, 0, no-repeat);
        margin: 0 0 16px 0;
      }

      > .tit {
        color: #222;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 58px;
      }

      > .tit2 {
        color: #222;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 58px;
      }

      > .sub-tit {
        margin: 0 0 16px 0;
        color: #222;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
      }

      > .sub-tit2 {
        margin: 0 0 10px 0;
        color: #0569ff;
        font-size: 22px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
      }

      > .sub-tit3 {
        margin: 0 0 40px 0;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }

      > .sub-tit4 {
        margin: 0 0 16px 0;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }

      > .txt {
        color: #888;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }

      > .txt2 {
        color: #888;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
      }

      > .btn {
        display: flex;
        width: 200px;
        margin: 16px auto 40px;
        padding: 14px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #ddd;
        background: #fff;
        cursor: pointer;

        &:hover {
          color: #000;
          background: #ddd;
        }
      }

      .info-img-box {
        @include flex;
        @include justify-content(space-between);
        margin: 100px 0 0;
      }

      .tit-img-box {
        @include flex;
        @include justify-content(space-between);

        .tit {
          color: #222;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 58px;
        }

        .txt {
          color: #888;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
        }

        .sub-tit {
          margin: 0 0 16px 0;
          color: #222;
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px;
        }

        .img-box {
          @include flex;
          @include justify-content(space-between);
        }

        .tit-txtbox {
          margin: 38px 0 0 0;
        }

        .tit-txtbox2 {
          margin: 68px 0 0 0;
        }
      }

      .subtit-box {
        @include flex;
        @include align-items(center);
        @include justify-content(space-between);
        height: 68px;
        margin: 0 0 16px 0;

        .tit {
          color: #000;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: 58px;
          letter-spacing: -2.4px;

          span {
            font-weight: 400;
          }

          &.tit2 {
            margin: 0 auto 16px;
          }
        }

        .txt {
          color: #888;
          text-align: right;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;

          span {
            font-weight: 700;
          }
        }
      }

      .logo-list {
        &::after {
          content: '';
          display: block;
          clear: both;
        }
      }

      &.door {
        padding: 0 0;

        .tit {
          margin: 150px 0 16px 0;
          color: #fff;
          font-size: 60px;
          font-style: normal;
          font-weight: 900;
          line-height: 68px;
          text-align: center;
        }

        .tit2 {
          margin: 150px 0 16px 0;
          color: #fff;
          font-size: 60px;
          font-style: normal;
          font-weight: 900;
          line-height: 68px;
        }

        .txt {
          color: #ccc;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          text-align: center;
        }

        .txt2 {
          color: #ccc;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
        }

        .btn-box {
          @include flex;
          justify-content: center;
          margin: 80px 0 156px 0;

          span,
          button,
          a {
            display: flex;
            width: 200px;
            padding: 14px 8px;
            justify-content: center;
            align-items: center;
            color: #222;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            border-radius: 8px;
            border: 1px solid #ddd;
            background-color: #fff;
            cursor: pointer;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
            margin: 0 16px 0 0;

            &:nth-child(1) {
              &:hover {
                color: #fff;
              }
            }

            &:nth-child(2) {
              color: #fff;
              border: 0;
              background: #0569ff;

              &:hover {
                background-color: #5493f2;
              }
            }

            &:nth-last-child(1) {
              margin: 0;
            }

            &:hover {
              background-color: #1d8de4;
              color: #fff;
              border-color: #007aff;
            }
          }

          &.btn-box2 {
            justify-content: flex-start;

            > span {
              display: flex;
              padding: 8px 12px 8px 16px;
              color: #ccc;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              align-items: center;
              border-radius: 100px;
              border: 1px solid rgba(255, 255, 255, 0.1);
              background: rgba(255, 255, 255, 0.1);

              &:hover {
                color: #000;
                background-color: #ccc;
              }
            }
          }
        }
      }
    }
  }
}

// height 100% layout
.layout-fixed-footer {
  @include flex;
  position: absolute;
  width: 100%;
  height: 100%;

  .fixed-footer-container {
    @include flex(column);
    flex: 1;
    padding: 50px 0 0 0;

    .content {
      margin: 0 0 0 220px;
      padding: 20px 30px;

      .content-tit {
        font-size: 24px;
        margin: 0 0 15px;
        border: none;
        line-height: 1.35;
        font-weight: 300;
      }

      .title-box {
        @include flex;
        @include align-items(center);
        margin: 0 0 15px;
        // box-shadow: inset 0px -1px 0px rgba(237, 242, 247, 1);
        h3 {
          font-size: 24px;
          border: none;
          line-height: 1.35;
          font-weight: 300;
        }

        .sub-title {
          color: rgba(32, 37, 42, 0.65);
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

// login layout
.layout-login {
  @include flex;
  @include width-height(100vw, 100vh);
  @include justify-content(center);
  @include align-items(center);
  font-family: 'Lato';
  // background-color: #eff1f3;
  background-color: #000;
  // text-align: center;
}

// loading layer
.loading-layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 300;

  .loading {
    @include absolute-left-top(50%, 50%);
    width: 100px;
    transform: translate(-50%, -50%);
  }

  .loading-img {
    @include absolute-left-top(50%, 49%);
    width: 40px;
    height: 40px;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  .donut {
    @include absolute-left-top(50%, 50%);
    display: inline-block;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-left-color: #7983ff;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    // background-color: #000;
    // transform:translate(-50%, -50%);
    animation: donut-spin 1.2s linear infinite;
  }

  @keyframes donut-spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

// // common table
// .tb-body {
//     border-radius: 5px 5px;
//     box-shadow: 0px 0px 1px 1px #edf2f7;
//     background-color: #fff;
//     .tb-tit {
//         @include flex;
//         border-radius: 5px 5px 0 0;
//         box-shadow: 0px -1px 0px 0px #edf2f7 inset;
//         background-color: rgba(45, 53, 60, 1);
//         span {
//             @include flex;
//             @include align-items(center);
//             @include justify-content(center);
//             padding: 10px 24px;
//             flex: 1;
//             // color: #8492a6;
//             color: #fff;
//             font-size: 12px;
//             font-weight: 400;
//         }
//     }
//     .tb-txt {
//         @include flex;
//         box-shadow: 0px -1px 0px 0px #edf2f7 inset;
//         span {
//             @include flex;
//             @include align-items(center);
//             @include justify-content(center);
//             flex: 1;
//             padding: 8px 24px;
//             font-size: 12px;
//             font-weight: normal;
//         }
//         &:nth-last-child(1) {
//             box-shadow: 0px 0px 0px 0px #edf2f7 inset;
//         }
//     }
// }

// common page banner
.page-banner {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  margin: 40px 0 20px 0;
  min-height: 120px;
  box-shadow: 0px 0px 0px 1px #edf2f7 inset;
  background-color: #fff;
}

// common data error
.data-error {
  padding: 30px 0 10px;
  color: #da4c4c;
  font-size: 14px;
}

// common data no
.no-data {
  width: 100%;
  padding: 30px 0 10px;
  color: #000;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 16px;
  text-align: center;
}

.qna-list {
  .tit {
    padding: 16px 0;
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;

    span {
      color: #0569ff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    &:hover {
      color: #0569ff;
    }
  }

  .txt {
    display: none;
    color: #888;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }

  &.open {
    .tit {
      color: #0569ff;
      border-bottom: 0;
    }

    .txt {
      display: block;
    }
  }
}

// tablet css
@include tablet {
  .parallax-layout {
    .go-up {
      bottom: 105px;
    }

    .container {
      padding: 44px 0 0 0;

      .section-full {
        width: 100%;

        &.section-full-sub-bg {
          height: 80px;
          padding: 0 16px;

          p {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }

      .section-part {
        display: block;
        width: 100%;
        margin: 0 auto;
        height: auto !important;
      }

      .section-content {
        padding: 44px 0 !important;
        text-align: center;

        .sub-logo {
          @include width-height(calc(152px * 0.75), calc(48px * 0.75));
          margin: 0 auto 16px;
        }

        > .tit {
          font-size: 34px;
          line-height: 42px;
          margin: 0 auto 16px !important;
        }

        > .txt,
        > .txt2 {
          font-size: 22px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: -0.64px;
          margin: 0 auto 24px !important;
        }

        > .sub-tit3,
        > .sub-tit4 {
          padding: 0 16px;
          font-size: 24px;
          line-height: 30px;
        }

        .subtit-box {
          display: block;

          .tit {
            font-size: 24px;
            line-height: 32px;
          }

          .txt {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
          }
        }

        .info-img-box {
          display: block;
          margin: 0 auto;
        }

        .tit-img-box {
          display: block;
          text-align: center;

          .tit {
            font-size: 34px;
            line-height: 42px;
            margin: 0 auto 16px !important;
          }

          .txt {
            padding: 0 16px;
            font-size: 22px;
            line-height: 28px;
            margin: 0 auto 24px !important;
          }

          .sub-tit {
            font-size: 30px;
            font-weight: 700;
            line-height: 34px;
            margin: 0 auto 16px !important;
          }

          .img-box {
            display: block;
            width: 100% !important;
          }

          .tit-txtbox {
            margin: 0 0;
          }

          .tit-txtbox2 {
            margin: 0 0;
          }
        }

        &.door {
          .sub-logo {
            @include width-height(calc(152px * 0.75), calc(48px * 0.75));
            margin: 0 auto 8px;
          }

          > .tit {
            font-size: 34px;
            line-height: 42px;
            margin: 0 auto 16px !important;
          }

          > .tit2 {
            font-size: 34px;
            line-height: 36px;
            margin: 111px auto 8px !important;
          }

          > .txt,
          > .txt2 {
            font-size: 22px;
            font-weight: 300;
            line-height: 28px;
            letter-spacing: -0.64px;
            margin: 0 auto 24px !important;
            text-align: center;
          }

          .btn-box {
            margin: 40px 0 40px 0;

            span {
              width: 100px;
              padding: 8px 8px;
              font-size: 18px;
              line-height: 22px;
            }

            &.btn-box2 {
              justify-content: center;
            }
          }
        }
      }

      .qna-list {
        .tit,
        .txt {
          font-size: 18px;
          line-height: 26px;
          text-align: left;
          padding: 10px 16px;
        }
      }
    }
  }
}

// mobile css
@include mobile {
  .parallax-layout {
    .go-up {
      bottom: 105px;
    }

    .container {
      padding: 44px 0 0 0;

      .section-full {
        width: 100%;

        .section-full-sub-bg {
          height: 80px;
          padding: 0 16px;

          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }

      .section-part {
        display: block;
        width: 100%;
        margin: 0 auto;
      }

      .section-content {
        padding: 24px 0 !important;
        text-align: center;

        .sub-logo {
          @include width-height(calc(152px * 0.6), calc(48px * 0.6));
          margin: 0 auto 8px;
        }

        > .tit {
          font-size: 24px;
          line-height: 32px;
          margin: 0 auto 8px !important;
        }

        > .txt,
        > .txt2 {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: -0.64px;
          margin: 0 auto 24px !important;
        }

        > .sub-tit3,
        > .sub-tit4 {
          padding: 0 16px;
          font-size: 20px;
          line-height: 28px;
        }

        .subtit-box {
          display: block;
          height: auto;

          .tit {
            font-size: 24px;
            line-height: 32px;
          }

          .txt {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
          }
        }

        .info-img-box {
          display: block;
          margin: 0 auto;
        }

        .tit-img-box {
          display: block;
          text-align: center;

          .tit {
            font-size: 24px;
            line-height: 32px;
            margin: 0 auto 16px !important;
          }

          .txt {
            padding: 0 16px;
            font-size: 16px;
            line-height: 24px;
            margin: 0 auto 24px !important;
          }

          .sub-tit {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            margin: 0 auto 16px !important;
          }

          .img-box {
            display: block;
            width: 100% !important;
          }

          .tit-txtbox {
            margin: 0 0;
          }

          .tit-txtbox2 {
            margin: 0 0;
          }
        }

        &.door {
          .sub-logo {
            @include width-height(calc(152px * 0.6), calc(48px * 0.6));
            margin: 0 auto 8px;
          }

          > .tit {
            font-size: 24px;
            line-height: 32px;
            margin: 0 auto 8px !important;
          }

          > .tit2 {
            font-size: 24px;
            line-height: 32px;
            margin: 111px auto 8px !important;
          }

          > .txt,
          > .txt2 {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: -0.64px;
            margin: 0 auto 24px !important;
            text-align: center;
          }

          .btn-box {
            margin: 40px 0 40px 0;

            span,
            a {
              width: 100px;
              padding: 8px 8px;
              font-size: 14px;
              line-height: 22px;
            }

            &.btn-box2 {
              justify-content: center;
            }
          }
        }
      }

      .qna-list {
        .tit,
        .txt {
          font-size: 14px;
          line-height: 22px;
          text-align: left;
          padding: 8px 16px;
        }
      }
    }
  }
}
