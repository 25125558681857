@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: top.scss
* description	: top CSS import
* author		: cardo
* date			: 2023.07.04
+ version		: v1.0
************************************************************************/

header {
  @include flex;
  flex: 1;
  position: fixed;
  // position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: box-shadow 0.2s linear;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  z-index: 99;

  &.opaque {
    background: rgba(0, 0, 0, 1);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }

  .top-content {
    @include flex;
    width: 1280px;
    height: 60px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;

    .top-logo {
      @include width-height(96px, 30px);
      @include background(url('../../../images/logo/logo.png'), 100%, 100%, 0, 0, no-repeat);
    }

    .top-menu {
      @include flex;

      & > div {
        position: relative;
        width: 120px;
      }

      & > .top-btn {
        @include flex;
        color: #fff;
        border: 0;
        padding: 0 10px;

        a {
          height: 40px;
          margin: auto;
          border-radius: 8px;
          color: #fff;
          border: 0;
          background: #0569ff;

          &:hover {
            background: #1d8de4;
          }

          &.btn_contact {
            color: #000;
            background: #fff;
            border: 1px solid #99a1a4;

            &:hover {
              //color: #fff;
              background: #eee;
            }
          }
        }
      }

      a {
        position: relative;
        @include flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        padding: 8px 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #222;
        transition: background-color 0.5s;
        -webkit-transition: background-color 0.5s;

        &:hover {
          color: #fff;
          font-weight: 700;
          background-color: #1a4ca1;

          .dropdown_menu li {
            display: block;
          }
        }

        &.active {
          color: #fff;
          font-weight: 700;
          background-color: #1a4ca1;
        }

        .dropdown_menu {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          perspective: 1000px;
          z-index: -1;
        }

        .dropdown_menu li {
          display: none;
          color: #fff;
          background-color: #7b98c9;
          // padding: 10px 20px;
          font-size: 16px;
          opacity: 0;
          text-align: center;

          span,
          a {
            position: relative;
            @include flex;
            align-items: center;
            justify-content: center;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            height: auto;
            color: #fff;
            padding: 12px 20px;

            &:hover {
              color: #fff;
              font-weight: 700;
              background-color: #1a4ca1;
            }
          }
        }

        .dropdown_menu li:hover {
          color: #000;
          background-color: #fff;
        }

        .dropdown_menu-2 .dropdown_item-1 {
          transform-origin: top center;
          animation: rotateX 100ms 30ms ease-in-out forwards;
        }

        .dropdown_menu-2 .dropdown_item-2 {
          transform-origin: top center;
          animation: rotateX 100ms 60ms ease-in-out forwards;
        }

        .dropdown_menu-2 .dropdown_item-3 {
          transform-origin: top center;
          animation: rotateX 100ms 90ms ease-in-out forwards;
        }

        .dropdown_menu-2 .dropdown_item-4 {
          transform-origin: top center;
          animation: rotateX 100ms 120ms ease-in-out forwards;
        }

        .dropdown_menu-2 .dropdown_item-5 {
          transform-origin: top center;
          animation: rotateX 100ms 150ms ease-in-out forwards;
        }

        @keyframes rotateX {
          0% {
            opacity: 0;
            transform: rotateX(-90deg);
          }
          50% {
            transform: rotateX(-20deg);
          }
          100% {
            opacity: 1;
            transform: rotateX(0deg);
          }
        }
      }
    }
  }

  .hamburger-menu {
    display: none;
  }
}

#menu__toggle {
  opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
  background-color: #fff;
}

#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
  background-color: #fff;
}

#menu__toggle:checked ~ .menu__box {
  right: 0 !important;
}

.menu__btn {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}

.menu__btn > span::before {
  content: '';
  top: -8px;
}

.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #000;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition-duration: 0.25s;
}

.menu__item {
  display: block;
  padding: 12px 24px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}

.menu__item:hover {
  background-color: #99a1a4;
}

// tablet css
@include tablet {
  header {
    &.opaque {
      color: #000;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

      .logo {
        width: 100%;
        @include justify-content(center);

        a {
          color: #000;
        }
      }
    }

    .top-content {
      height: 44px;
      padding: 10px 16px;

      .logo {
        width: 141px;
        height: 24px;
      }

      .top-menu {
        display: none;
      }

      .hamburger-menu {
        display: block;
      }

      .menu__btn {
        display: block;
      }
    }
  }
}

// mobile css
@include mobile {
  header {
    &.opaque {
      color: #000;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

      .logo {
        width: 100%;
        @include justify-content(center);

        a {
          color: #000;
        }
      }
    }

    .top-content {
      height: 44px;
      padding: 10px 16px;

      .logo {
        width: 141px;
        height: 24px;
      }

      .top-menu {
        display: none;
      }

      .menu__btn {
        display: block;
      }

      .hamburger-menu {
        display: block;
      }
    }
  }
}
