@charset "utf-8";
@import '@style/mixin';

.sto-door {
  @include background(url('../../../images/solution/sto_bg.png'), 100%, 100%, 0, 0, no-repeat);
}

.parallax-layout {
  .container {
    .solution {
      .section-full {
        width: 100%;
        .section-part {
          width: 1280px;
          .section-content {
            &.sto-slider {
              @include flex;
              @include align-items(center);
              @include justify-content(center);
              // padding: 60px 0;
              .slider-tit {
                width: 430px;
                font-size: 55px;
                font-style: normal;
                font-weight: 700;
                line-height: 68px;

                .sub-logo {
                  margin: 10px 24px 10px 0;
                }

                &.slider-tit2 {
                  font-size: 50px;
                }

                &.slider-tit3 {
                  padding: 0 0 0 100px;

                  .sub-logo {
                    margin: 10px 24px 5px 0;
                  }
                }
              }

              .slider-txt {
                margin: 10px 0 0 0;
                color: #888;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 25px;
              }
            }
          }
        }
      }

      .section-validator {
        .section-full:nth-child(even) {
          background-color: #ddebff;
        }
      }

      .section-sto {
        .section-header {
          width: 100%;

          .section-part {
            width: 1280px;

            .tit {
              color: #222;
              font-size: 50px;
              font-style: normal;
              font-weight: 700;
              line-height: 58px;
            }
          }

          .swiper-slide {
            img {
              max-height: 400px;
            }
          }
        }

        .section-full:nth-child(odd) {
          background-color: #ddebff;
        }
      }
    }
  }
}

.sto-mobile,
.sto-mobile2,
.sto-mobile3 {
  width: 850px;
  height: 540px;
  text-align: center;
}

//.sto-img1 {
//  @include width-height(716px, 480px);
//  @include background(url('../../../images/solution/sto_img1_2.png'), 100%, 100%, 0, 0, no-repeat);
//  border-radius: 20px 20px;
//}
//
//.sto-img2 {
//  @include width-height(1280px, 472px);
//  @include background(url('../../../images/solution/sto_img2.png'), 100%, 100%, 0, 0, no-repeat);
//}
//
//.sto-img3 {
//  @include width-height(1280px, 160px);
//  @include background(url('../../../images/solution/sto_img3.png'), 100%, 100%, 0, 0, no-repeat);
//}
//
//.sto-img4 {
//  @include width-height(880px, 160px);
//  @include background(url('../../../images/solution/sto_img4.png'), 100%, 100%, 0, 0, no-repeat);
//}
//
//.sto-img5 {
//  @include width-height(540px, 40px);
//  @include background(url('../../../images/solution/sto_img5.png'), 100%, 100%, 0, 0, no-repeat);
//}
//
//.sto-img6 {
//  @include width-height(1280px, 598px);
//  @include background(url('../../../images/solution/sto_img6.png'), 100%, 100%, 0, 0, no-repeat);
//}

.sto-img7 {
  @include width-height(433px, 240px);
  @include background(url('../../../images/solution/sto_img7.png'), 100%, 100%, 0, 0, no-repeat);
}

.sto-img8 {
  @include width-height(400px, 240px);
  @include background(url('../../../images/solution/sto_img8.png'), 100%, 100%, 0, 0, no-repeat);
}

.b-sto-bg {
  @include background(url('../../../images/solution/point_banner.png'), 100%, 100%, 0, 0, no-repeat);
}

.sto-logo {
  span {
    @include width-height(244px, 160px);
    float: left;
    margin: 0 15px 15px 0;

    &:nth-child(5) {
      margin: 0 0 15px 0;
    }

    &.logo1 {
      @include background(url('../../../images/solution/sto_logo1.png'), 100%, 100%, 0, 0, no-repeat);
    }

    &.logo2 {
      @include background(url('../../../images/solution/sto_logo2.png'), 100%, 100%, 0, 0, no-repeat);
    }

    &.logo3 {
      @include background(url('../../../images/solution/sto_logo3.png'), 100%, 100%, 0, 0, no-repeat);
    }

    &.logo4 {
      @include background(url('../../../images/solution/sto_logo4.png'), 100%, 100%, 0, 0, no-repeat);
    }

    &.logo5 {
      @include background(url('../../../images/solution/sto_logo5.png'), 100%, 100%, 0, 0, no-repeat);
    }
  }
}

.solution-door {
  @include background(url('../../../images/solution/sto_bg.png'), 100%, 100%, 0, 0, no-repeat);
  .btn-box {
    a {
      margin: 0 16px 0 0;

      &:nth-last-child(1) {
        margin: 0 0 0 0;
      }

      &:hover {
        background-color: #1d8de4;
        color: #fff;
        border-color: #007aff;
      }
    }
  }
}

.logo-staking {
  @include width-height(480px, 280px);
  @include background(url('../../../images/solution/staking.jpg'), 100%, 100%, 0, 0, no-repeat);
}

.logo-validator {
  @include width-height(480px, 280px);
  @include background(url('../../../images/solution/validator.jpg'), 100%, 100%, 0, 0, no-repeat);
}

.logo-ha {
  @include width-height(480px, 280px);
  @include background(url('../../../images/solution/ha.jpg'), 100%, 100%, 0, 0, no-repeat);
}

// tablet css
@include tablet {
  .parallax-layout {
    .container {
      .solution {
        .section-full {
          .section-part {
            width: 100%;
            .section-content {
              &.sto-slider {
                .slider-tit {
                  width: 100%;
                  margin: 0 0 16px 0;
                  font-size: 34px;
                  line-height: 42px;
                  flex: 1;

                  .sub-logo {
                    margin: 0 auto 16px;
                  }

                  &.slider-tit2 {
                    margin: 0 0 16px 0;
                    padding: 0 0;
                    font-size: 24px;
                    line-height: 32px;

                    .sub-logo {
                      margin: 24px auto 16px;
                    }
                  }

                  &.slider-tit3 {
                    padding: 0 0;

                    .sub-logo {
                      margin: 24px auto 16px;
                    }
                  }
                }
              }

              .sto-img1 {
                width: 583px;
                height: 304px;
                margin: 0 auto 16px;
              }

              .sto-img2 {
                width: 343px;
                height: 592px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img2.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img3 {
                width: 343px;
                height: 684px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img3.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img4 {
                width: 343px;
                height: 336px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img4.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img5 {
                width: 443px;
                height: 35px;
                margin: 0 auto 16px;
              }

              .sto-img6 {
                width: 583px;
                height: 306px;
                margin: 0 auto 16px;
              }

              .sto-img7 {
                width: 343px;
                height: 180px;
                margin: 0 auto 16px;
              }

              .sto-img8 {
                width: 343px;
                height: 180px;
                margin: 0 auto 16px;
              }

              .sto-logo {
                display: block;
                margin: 0 0;
                padding: 0 16px;
                text-align: center;

                span {
                  display: inline-block;
                  float: initial;
                  width: 164px;
                  height: 158px;
                  margin: 0 3px 0;
                  border-radius: 5px 5px;
                  border: 1px solid #ddd;

                  &.logo1,
                  &.logo2,
                  &.logo3,
                  &.logo4,
                  &.logo5 {
                    background-size: 244px 160px;
                    background-position: center;
                    // background-size: cover;
                  }

                  &:nth-child(5) {
                    margin: 0 0 0 0;
                  }
                }
              }
            }
          }
        }
      }
      .solution-door {
        .section-content {
          &.door {
            padding: 50px 0 24px 0 !important;
            .door-text {
              font-size: 1rem;
              padding: 0 10px;
            }
          }

          &.sto-slider {
            display: block;
          }
        }
      }
    }
  }

  .logo-staking,
  .logo-validator,
  .logo-ha {
    @include width-height(calc(480px * 0.75), calc(280px * 0.75));
    margin: auto;
  }

  .sto-mobile,
  .sto-mobile2,
  .sto-mobile3 {
    width: 50%;
  }
}

// mobile css
@include mobile {
  .parallax-layout {
    .container {
      .solution {
        .sto-door {
          .section-content {
            &.door {
              padding: 50px 0 24px 0 !important;
            }
          }
        }
        .section-full {
          .section-part {
            .section-content {
              &.sto-slider {
                display: block;
                margin: 0 0 50px 0;

                .slider-tit {
                  width: 100%;
                  margin: 0 0 16px 0;
                  font-size: 24px;
                  line-height: 32px;

                  .sub-logo {
                    margin: 0 auto 16px;
                  }

                  &.slider-tit2 {
                    margin: 0 0 16px 0;
                    padding: 0 0;
                    font-size: 24px;
                    line-height: 32px;

                    .sub-logo {
                      margin: 24px auto 16px;
                    }
                  }

                  &.slider-tit3 {
                    padding: 0 0;

                    .sub-logo {
                      margin: 24px auto 16px;
                    }
                  }
                }

                .slider-txt {
                  font-size: 16px;
                  line-height: 24px;
                }

                .sto-mobile,
                .sto-mobile2,
                .sto-mobile3 {
                  // width: 343px;
                  // height: 540px;
                  width: 300px;
                  height: 500px;

                  .swiper-slide {
                    img {
                      width: 260px;
                      height: 540px;
                    }
                  }
                }

                &.m-sto-slider {
                  position: relative;
                  padding: 176px 0 0 0 !important;

                  .slider-tit3 {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                }
              }

              .sto-img1 {
                // width: 343px;
                // height: 154px;
                width: 300px;
                height: 200px;
                margin: 0 auto 16px;
              }

              .sto-img2 {
                // width: 343px;
                // height: 592px;
                width: 300px;
                height: 562px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img2.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img3 {
                // width: 343px;
                // height: 684px;
                width: 300px;
                height: 654px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img3.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img4 {
                // width: 343px;
                // height: 336px;
                width: 300px;
                height: 306px;
                margin: 0 auto 16px;
                @include background(url('../../../images/solution/m_sto_img4.png'), 100%, 100%, 0, 0, no-repeat);
              }

              .sto-img5 {
                // width: 343px;
                width: 300px;
                height: 22px;
                margin: 0 auto 16px;
              }

              .sto-img6 {
                // width: 343px;
                width: 300px;
                height: 146px;
                margin: 0 auto 16px;
              }

              .sto-img7 {
                // width: 343px;
                width: 300px;
                height: 160px;
                margin: 0 auto 16px;
              }

              .sto-img8 {
                // width: 343px;
                width: 300px;
                height: 160px;
                margin: 0 auto 16px;
              }

              .sto-logo {
                display: block;
                margin: 0 0;
                padding: 0 16px;
                text-align: center;

                span {
                  display: inline-block;
                  float: initial;
                  // width: 164px;
                  // height: 160px;
                  width: 124px;
                  height: 120px;
                  margin: 0 auto 0;
                  border-radius: 5px 5px;
                  border: 0;

                  &:nth-child(even) {
                    margin: 0 0 0 5px;
                  }

                  &.logo1 {
                    @include background(url('../../../images/solution/m_sto_logo1.png'), 100%, 100%, 0, 0, no-repeat);
                  }

                  &.logo2 {
                    @include background(url('../../../images/solution/m_sto_logo2.png'), 100%, 100%, 0, 0, no-repeat);
                  }

                  &.logo3 {
                    @include background(url('../../../images/solution/m_sto_logo3.png'), 100%, 100%, 0, 0, no-repeat);
                  }

                  &.logo4 {
                    @include background(url('../../../images/solution/m_sto_logo4.png'), 100%, 100%, 0, 0, no-repeat);
                  }

                  &.logo5 {
                    @include background(url('../../../images/solution/m_sto_logo5.png'), 100%, 100%, 0, 0, no-repeat);
                  }

                  // &.logo1,
                  // &.logo2,
                  // &.logo3,
                  // &.logo4,
                  // &.logo5 {
                  //     background-size: 244px 160px;
                  //     background-position: center;
                  //     // background-size: cover;
                  // }
                  // &:nth-child(5) {
                  //     margin: 0 0 0 0;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
}
