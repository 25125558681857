@charset "utf-8";
@import '@style/mixin';
/************************************************************************
* filename		: popup.scss
* description	: popup CSS import
* author		: cardo
* date			: 2023.07.04
+ version		: v1.0
************************************************************************/
.popup-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(49, 49, 49, 0.6);
  z-index: 10000;
  display: flex;
  transition: all 0.5s;
  flex-direction: column;
  .popup-container {
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: row;
    transition: all 0.5s;
    .popup-area {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      transition: all 0.5s;

      .popup {
        position: relative;
        margin: 10px;
        width: 500px;
        height: 500px;
        background-color: #fff;
        transition: all 0.5s;
        .close {
          position: absolute;
          top: 3px;
          right: 15px;
          font-size: 30px;
          font-weight: bold;
          text-decoration: none;
          color: #333;
          cursor: pointer;
          transition: all 0.3s ease-out;
        }
        .tit {
          height: 50px;
          padding: 13px 20px 15px;
          font-size: 18px;
          font-weight: 600;
          border-bottom: 1px solid #747373;
        }
        .popup-content {
          max-height: 450px;
          overflow-y: auto;
        }
        .txt {
          font-family: Pretendard Variable, Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
            Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol, sans-serif;
          padding: 20px 20px;
          font-size: 16px;
          font-weight: 400;
          a {
            display: inline;
            color: #007aff;
            width: auto;
            text-decoration: underline;
            &:hover {
              color: #82addc;
            }
          }
          ol,
          ol > li {
            list-style: decimal;
            margin-left: 20px;
          }
          ul,
          ul > li {
            list-style: disc;
            margin-left: 20px;
          }
          ul,
          ol {
            margin-top: 5px;
          }
          li {
            margin-top: 5px;
          }
        }
        .txt-long {
          font-family: Pretendard Variable, Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
            Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol, sans-serif;
          padding: 20px 20px;
          font-size: 14px;
          a {
            display: inline;
            color: #007aff;
            width: auto;
            text-decoration: underline;
            &:hover {
              color: #82addc;
            }
          }
          .notice-long {
            font-size: 16px;
            font-weight: normal;
          }
          p {
            margin-top: 0;
            margin-bottom: 1rem;
          }
          .my-4 {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
          }
          .ml-4 {
            margin-left: 1.5rem;
          }
          .mt-5 {
            margin-top: 3rem;
          }
          .text-center {
            text-align: center;
          }
          .font-weight-bold {
            font-weight: bold;
          }
        }
        .footer {
          width: 100%;
          position: absolute;
          bottom: 0;
          padding: 20px;
          text-align: center;
        }
      }
    }
  }
}
