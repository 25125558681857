@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: footer.scss
* description	: footer CSS import
* author		: kurt
* date			: 2023.07.04
+ version		: v1.0
************************************************************************/

footer {
  position: relative;
  color: #000;
  background-color: #ddd;

  .footer-content {
    position: relative;
    width: 1280px;
    margin: 0 auto;
    padding: 40px 0;

    .footer-split {
      @include flex;
      width: 100%;
      justify-content: space-between;
      .link-box {
        @include flex;
        ul {
          display: inline-block;
          width: auto;
          &.customer-link {
            margin-left: 30px;
          }
        }
      }
    }
  }

  .footer-img {
    display: inline-block;
    margin: 0 0 10px 0;

    span {
      margin: 0 20px 10px 0;
      display: inline-block;

      &.isms-logo {
        @include width-height(55px, 55px);
        @include background(url('../../../images/certification/isms.png'), 100%, 100%, 0, 0, no-repeat);
      }

      &.soc-logo {
        @include width-height(95px, 55px);
        @include background(url('../../../images/certification/soc.png'), 100%, 100%, 0, 0, no-repeat);
      }
    }
  }

  .footer-logo {
    @include width-height(188px, 32px);
    @include background(url('../../../images/logo/logo_bottom.png'), 100%, 100%, 0, 0, no-repeat);
    margin: 0 0 40px 0;
  }

  .company-info {
    @include flex;
    margin: 0 0 8px 0;

    .tit {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      height: 24px;
      margin: 0 8px 0 0;
      padding: 0 8px;
      color: #888;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-radius: 4px;
      background-color: #ccc;
    }

    .txt {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .company-txt {
    margin: 16px 0;
    color: #888;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .reserved {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 30px;
  }

  .footer-link {
    //position: absolute;
    //right: 184px;
    //top: 40px;
    min-width: 60px;
    li {
      height: 22px;
      line-height: 22px;
      margin: 0 0 8px 0;
      letter-spacing: 1px;
      text-align: left;

      a {
        position: relative;
        display: block;
        color: #333;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        &:hover {
          color: #000;
          font-weight: bold;
        }
      }
    }
  }

  .customer-link {
    //position: absolute;
    //right: 0;
    //top: 40px;

    li {
      height: 22px;
      line-height: 22px;
      margin: 0 0 8px 0;
      letter-spacing: 1px;
      text-align: left;

      a {
        position: relative;
        display: block;
        color: #333;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        &:hover {
          font-weight: bold;
        }
      }

      &.privacy {
        a {
          font-weight: 600;
          font-size: 16px;
          &:hover {
            font-weight: 900;
          }
        }
      }
    }
  }
}

// tablet css
@include tablet {
  footer {
    .footer-content {
      width: 100%;
      padding: 24px 16px;
    }

    .footer-logo {
      margin: 0 0 24px 0;
    }

    .company-info {
      &:nth-child(3) {
        .tit {
          min-width: 50px;
          margin: 0 0 5px 0;
        }
      }
    }

    .link-box {
      display: flex;
    }

    .footer-link,
    .customer-link {
      position: relative;
      right: inherit;
      top: inherit;
      margin: 24px 0 0 0;
      font-size: 14px;
      width: 50%;
    }
  }
}

// mobile css
@include mobile {
  footer {
    .footer-content {
      width: 100%;
      padding: 24px 16px;
      .footer-split {
        display: block;
        .link-box {
          ul {
            &.customer-link {
              margin-left: 0;
            }
          }
        }
      }
    }

    .footer-logo {
      margin: 0 0 24px 0;
    }

    .company-info {
      &:nth-child(3) {
        .tit {
          min-width: 50px;
          margin: 0 0 5px 0;
        }
      }
    }

    .link-box {
      @include flex;
      @include justify-content(space-between);
    }

    .footer-link,
    .customer-link {
      flex: 1;
      position: relative;
      right: inherit;
      top: inherit;
      margin: 24px 0 0 0;
      font-size: 14px;
      width: 50%;
    }
  }
}
