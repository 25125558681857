@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: custody.scss
* description	: custody CSS import
* author		: kurt
* date			: 2024.02.06
+ version		: v1.0
************************************************************************/

.custody-content {
  width: 1280px;
  padding: 0 0 0 0;

  .custody-info {
    margin: auto 0;
  }

  .sub-logo {
    @include width-height(152px, 48px);
    @include background(url('../../../images/logo/logo.png'), 100%, 100%, 0, 0, no-repeat);
    margin: 0 0 16px 0;
  }

  .tit {
    color: #222;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
  }

  .sub-tit {
    margin: 0 0 16px 0;
    color: #222;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
  }

  .txt {
    color: #888;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .btn-box {
    @include flex;
    margin: 80px 0 100px 0;

    span,
    a {
      display: flex;
      width: 250px;
      padding: 14px 8px;
      justify-content: center;
      align-items: center;
      color: #222;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      border-radius: 8px;
      border: 1px solid #ddd;
      background-color: #fff;
      cursor: pointer;

      &:nth-child(1) {
        margin: 0 16px 0 0;

        &:hover {
          color: #fff;
          background-color: #000;
        }
      }

      &:nth-child(2) {
        color: #fff;
        border: 0;
        background: #0569ff;

        &:hover {
          background-color: #5493f2;
        }
      }
    }
  }

  &.door {
    .tit {
      margin: 150px 0 20px 0;
      font-size: 65px;
      color: #0569ff;
    }

    .tit,
    .sub-tit,
    .txt {
      text-align: center;
    }

    .btn-box {
      justify-content: center;
    }
  }

  &.center {
    .sub-logo {
      margin: 100px auto 16px;
    }

    .tit {
      margin: 0 0 100px 0;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px;
      text-align: center;
    }

    .txt {
      text-align: center;
    }
  }

  .coin-box {
    @include flex;
    @include justify-content(space-around);
    padding: 40px 0 120px;

    span {
      height: 120px;
      min-width: 80px;
      padding: 88px 0 0 0;
      text-align: center;
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;

      &.btc {
        @include background(url('../../../images/custody/bitcoin.png'), 80px, 80px, 0, 0, no-repeat);
      }

      &.eth {
        @include background(url('../../../images/custody/ethereum.png'), 80px, 80px, 0, 0, no-repeat);
      }

      &.klay {
        @include background(url('../../../images/custody/kaia.png'), 80px, 80px, 0, 0, no-repeat);
      }

      &.matic {
        @include background(url('../../../images/custody/matic.png'), 80px, 80px, 50%, 0, no-repeat);
      }

      &.sol {
        @include background(url('../../../images/custody/solana.png'), 80px, 80px, 0, 0, no-repeat);
      }

      &.bnb {
        @include background(url('../../../images/custody/bnb.png'), 80px, 80px, 0, 0, no-repeat);
      }

      &.aptos {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          @include background(url('../../../images/custody/aptos.png'), 80px, 80px, 50%, 50%, no-repeat);
        }
      }

      &.pci {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 78px;
          height: 78px;
          border-radius: 50%;
          border-style: solid;
          border-width: 1px;
          border-color: #bbb;
          @include background(url('../../../images/custody/pci.png'), 60px, 46px, 50%, 50%, no-repeat);
        }
      }

      &.after-add {
        position: relative;
        font-size: 20px;

        &::before {
          @include width-height(80px, 80px);
          content: '+';
          color: #fff;
          line-height: 67px;
          position: absolute;
          top: 0;
          left: 0;
          padding: 0;
          background: #000;
          border-radius: 50%;
          font-size: 100px;
        }
      }
    }

    &.coin-box2 {
      @include justify-content(space-evenly);
    }
  }

  .security-box {
    @include flex;
    @include justify-content(space-between);
    margin: 100px 0;

    .security-img1 {
      @include width-height(410px, 160px);
      @include background(url('../../../images/custody/security_img1.png'), 100%, 100%, 0, 0, no-repeat);
    }

    .security-img2 {
      @include width-height(410px, 160px);
      @include background(url('../../../images/custody/security_img2.png'), 100%, 100%, 0, 0, no-repeat);
    }

    .security-img3 {
      @include width-height(410px, 160px);
      @include background(url('../../../images/custody/security_img3.png'), 100%, 100%, 0, 0, no-repeat);
    }
  }

  .withdrawal-process-box {
    @include flex;
    @include justify-content(space-between);
    margin-top: 30px;

    img {
      vertical-align: middle;
      border-style: none;
    }

    .arrow {
      float: left;
      width: 36px;
      height: 89px;
      margin-top: 55px;
      margin-left: 10px;
      margin-right: 10px;
      object-fit: contain;
    }

    .box {
      position: relative;
      float: left;
      width: calc((100% - 180px) / 4);
      width: -webkit-calc((100% - 180px) / 4);
      width: -moz-calc((100% - 180px) / 4);
      height: 200px;
      border: 1px solid #888;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-shadow: 1px 1px 1px #888;

      img {
        position: absolute;
        left: calc(50% - 80px);
        left: -webkit-calc(50% - 80px);
        left: -moz-calc(50% - 80px);
        top: 20px;
        width: 60px;
        height: 60px;
      }

      h1 {
        position: absolute;
        left: 50%;
        top: 20px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-weight: bold;
        font-size: 20px;
        color: #000;
        text-align: center;
      }

      h2 {
        position: absolute;
        left: 0;
        top: 110px;
        width: 100%;
        line-height: 20px;
        color: #444;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        font-weight: normal;
      }
    }
  }

  .tit-img-box {
    @include flex;
    @include justify-content(space-between);
    //border: solid 1px #ddd;

    //.security {
    //  @include width-height(590px, 320px);
    //  @include background(url('../../../images/custody/security.png'), 100%, 100%, 0, 0, no-repeat);
    //  margin: 0 100px 100px 0;
    //}

    .cold-wallet {
      @include width-height(590px, 320px);
      @include background(url('../../../images/custody/coldwallet.png'), 100%, 100%, 0, 0, no-repeat);
      //margin: 0 0 100px 100px;
    }

    .authority {
      @include width-height(480px, 328px);
      @include background(url('../../../images/custody/authority.png'), 100%, 100%, 0, 0, no-repeat);
      //margin: 0 0 100px 100px;
    }

    .logo-soc {
      @include width-height(480px, 280px);
      @include background(url('../../../images/certification/soc.png'), 100%, 100%, 0, 0, no-repeat);
    }

    .boxArea {
      @include width-height(380px, 380px);
      display: grid;
      grid-template-columns: 1fr 1fr;

      .box {
        background-color: #fff;
        text-align: center;
        border: solid 1px #ddd;
        border-radius: 8px;
        margin: 5px;

        img {
          margin: 20px auto 10px;
          width: 120px;
          height: 120px;
          object-fit: contain;
        }
      }
    }
  }

  &.content-split {
    @include flex;
    width: 100%;
    padding: 100px 0 80px;
    justify-content: space-between;
  }

  .partners-tit {
    @include flex;
    @include align-items(center);
    @include justify-content(space-between);
    height: 68px;

    .tit {
      margin: 0 0 16px 0;
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: 58px;
      letter-spacing: -2.4px;
    }

    .txt {
      color: #888;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;

      span {
        font-weight: 700;
      }
    }
  }

  .partners-list {
    margin: 0 0 144px 0;

    span {
      @include width-height(308px, 160px);
      float: left;
      margin: 0 16px 16px 0;

      &:nth-child(4),
      &:nth-child(8) {
        margin: 0 0 16px 0;
      }
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &.pt80 {
    padding: 80px 0 0 0;
  }

  &.pt100 {
    padding: 100px 0 0 0;
  }
}

.txt-box {
  width: 100%;
  padding: 40px 0;
  color: #fff;
  text-align: center;

  .txt1 {
    margin: 0 0 8px 0;
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;

    span {
      font-weight: 700;
    }
  }

  .txt2 {
    margin: 0 0 24px 0;
    color: #888;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  }

  .btn {
    display: flex;
    @include width-height(126px, 40px);
    margin: 0 auto 0;
    padding: 8px 12px 8px 16px;
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    align-items: center;
    border-radius: 100px;
    background: #fff;
    cursor: pointer;
    @include background(url('../../../images/common/swap_right_w.png'), 20px, 20px, 95px, 8px, no-repeat);
  }
}

.cardo-txt {
  @include flex;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;
  height: 112px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px;

  span {
    font-weight: 500;
  }
}

// tablet css
@include tablet {
  .custody-info {
    padding: 0 10px;
  }
  .custody-content {
    width: 100%;
  }
  .custody-content.door {
    .tit {
      margin: 56px 0 8px 0;
      font-size: 42px;
    }

    .sub-tit {
      font-size: 26px;
    }

    .txt {
      font-size: 18px;
    }

    .btn-box {
      margin: 24px 0 44px 0;

      span {
        width: 100px;
        padding: 8px 8px;
        font-size: 18px;
      }
    }
  }

  .custody-content {
    .sub-logo {
      width: 133px;
      height: 42px;
      margin: 24px auto 8px;
    }

    .tit {
      margin: 0 0 24px 0;
      font-size: 34px;
      line-height: 42px;
      text-align: center;
    }

    .txt {
      font-size: 22px;
      line-height: 26px;
      text-align: center;
    }

    &.center {
      .sub-logo {
        margin: 24px auto 8px;
      }

      .tit {
        margin: 0 0 24px 0;
        font-size: 34px;
      }
    }

    .coin-box {
      display: block;
      padding: 0 0 24px;
      text-align: center;

      span {
        display: inline-block;
        width: 86px;
        height: 92px;
        margin: 0 0 8px 0;
        padding: 44px 0 0 0;
        font-size: 20px;

        &.btc,
        &.eth,
        &.klay,
        &.matic,
        &.sol,
        &.ont,
        &.bnb {
          background-size: 40px 40px;
          background-position: center 0;
        }
        &.aptos::before {
          width: 40px;
          height: 40px;
          background-size: 40px 40px;
          left: calc(50% - 20px);
        }
        &.pci::before {
          width: 38px;
          height: 38px;
          background-size: 30px 26px;
          left: calc(50% - 20px);
        }

        &.after-add {
          font-size: 16px;

          &::before {
            @include width-height(40px, 40px);
            content: '+';
            color: #fff;
            line-height: 36px;
            position: absolute;
            top: 0;
            left: calc(50% - 20px);
            padding: 0;
            background: #000;
            border-radius: 50%;
            font-size: 48px;
          }
        }
      }
    }

    .security-box {
      display: block;
      margin: 24px 0 0 0;
      padding: 0 16px;

      .security-img1,
      .security-img2,
      .security-img3 {
        width: 100%;
        max-width: 343px;
        height: 136px;
        margin: 0 auto 24px;
      }
    }

    .withdrawal-process-box {
      .box {
        img {
          position: absolute;
          left: calc(50% - 70px);
          left: -webkit-calc(50% - 70px);
          left: -moz-calc(50% - 70px);
          top: 20px;
          width: 60px;
          height: 60px;
        }

        h1 {
          position: absolute;
          left: 50%;
          top: 20px;
          width: 60px;
          height: 60px;
          line-height: 60px;
          font-weight: bold;
          font-size: 20px;
          color: #000;
          text-align: center;
        }

        h2 {
          position: absolute;
          left: 0;
          top: 100px;
          width: 100%;
          line-height: 20px;
          color: #444;
          font-size: 12px;
          padding-left: 10px;
          padding-right: 10px;
          font-weight: normal;
        }
      }
    }

    .tit-img-box {
      display: block;
      padding: 0 16px;
      margin: 0 auto;

      .security {
        width: 100%;
        max-width: 343px;
        height: 186px;
        margin: 0 auto;
      }

      .cold-wallet {
        width: 100%;
        max-width: 343px;
        height: 186px;
        margin: 24px auto 0;
      }

      .authority {
        width: 100%;
        max-width: 343px;
        height: 240px;
        margin: 24px auto 0;
      }

      .logo-soc {
        width: 100%;
        max-width: 270px;
        height: 160px;
        margin: 24px auto 0;
      }

      .boxArea {
        margin: 10px auto 0;
      }
    }

    &.pt80 {
      padding: 24px 0 0 0;
    }

    &.pt100 {
      padding: 24px 0 24px 0;
    }

    .partners-tit {
      display: block;
      height: auto;
      margin: 0 0 24px 0;

      > .tit {
        display: block;
        margin: 0 0 8px 0;
      }

      > .txt {
        display: block;
        text-align: center;
      }
    }

    .partners-list {
      display: block;
      margin: 0 0;
      padding: 0 16px;
      text-align: center;

      span {
        display: inline-block;
        float: initial;
        width: 164px;
        height: 160px;
        border-radius: 5px 5px;

        &.part-img1,
        &.part-img2,
        &.part-img3,
        &.part-img4,
        &.part-img5,
        &.part-img6,
        &.part-img7,
        &.part-img8,
        &.part-img9,
        &.part-img10,
        &.part-img11 {
          background-position: center;
          background-size: cover;
        }
      }
    }

    &.content-split {
      display: block;
      width: 100%;
      padding: 20px 0;
    }
  }

  .txt-box {
    padding: 24px 16px;

    .txt1 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }

    .txt2 {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }

    .btn {
      width: 100px;
      height: 26px;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .cardo-txt {
    display: block;
    height: 100%;
    padding: 24px 16px;
    text-align: center;

    .logo-w {
      display: inline-block;
      width: 140px;
      height: 24px;
      margin: 0 0 24px 0;
      padding: 0 0;
      font-size: 0;
      background-size: 100% 100%;
    }
  }
}

// mobile css
@include mobile {
  .custody-content {
    width: 100%;
  }
  .custody-content.door {
    .tit {
      margin: 56px 0 8px 0;
      font-size: 32px;
    }

    .sub-tit {
      font-size: 16px;
    }

    .txt {
      font-size: 14px;
    }

    .btn-box {
      margin: 24px 0 44px 0;

      span,
      a {
        width: 180px;
        padding: 8px 8px;
        font-size: 14px;
      }
    }
  }

  .custody-content {
    .sub-logo {
      width: 100px;
      height: 32px;
      margin: 24px auto 8px;
    }

    .tit {
      margin: 0 0 24px 0;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    .txt {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &.center {
      .sub-logo {
        margin: 24px auto 8px;
      }

      .tit {
        margin: 0 0 24px 0;
        font-size: 24px;
      }
    }

    .coin-box {
      display: block;
      padding: 0 0 24px;
      text-align: center;

      span {
        display: inline-block;
        width: 66px;
        height: 72px;
        margin: 0 0 8px 0;
        padding: 44px 0 0 0;
        font-size: 16px;

        &.btc,
        &.eth,
        &.klay,
        &.matic,
        &.sol,
        &.ont,
        &.bnb {
          background-size: 40px 40px;
          background-position: center 0;
        }
      }
    }

    .security-box {
      display: block;
      margin: 24px 0 0 0;
      padding: 0 16px;

      .security-img1,
      .security-img2,
      .security-img3 {
        width: 100%;
        // max-width: 343px;
        // height: 136px;
        max-width: 300px;
        height: 120px;
        margin: 0 auto 24px;
      }
    }

    .tit-img-box {
      display: block;
      padding: 0 16px;
      margin: 0 auto;

      .security {
        width: 100%;
        // max-width: 343px;
        // height: 186px;
        max-width: 300px;
        height: 130px;
        margin: 0 auto;
      }

      .cold-wallet {
        width: 100%;
        // max-width: 343px;
        // height: 186px;
        max-width: 300px;
        height: 130px;
        margin: 24px auto 0;
      }

      .authority {
        width: 100%;
        // max-width: 343px;
        // height: 240px;
        max-width: 300px;
        height: 190px;
        margin: 24px auto 0;
      }

      .boxArea {
        @include width-height(300px, 300px);
        margin: 10px auto 0;

        .box {
          margin: 5px;

          img {
            margin: 10px auto 5px;
            width: 100px;
            height: 100px;
          }

          p {
            font-size: 0.85rem;
          }
        }
      }
    }

    &.pt80 {
      padding: 24px 0 0 0;
    }

    &.pt100 {
      padding: 24px 0 24px 0;
    }

    .partners-tit {
      display: block;
      height: auto;
      margin: 0 0 24px 0;

      > .tit {
        display: block;
        margin: 0 0 8px 0;
      }

      > .txt {
        display: block;
        text-align: center;
      }
    }

    .partners-list {
      display: block;
      margin: 0 0;
      padding: 0 16px;
      text-align: center;

      span {
        display: inline-block;
        float: initial;
        // width: 164px;
        // height: 160px;
        width: 124px;
        height: 120px;
        border-radius: 5px 5px;
      }
    }

    .withdrawal-process-box {
      display: block;
      width: 100%;
      height: 500px;

      img {
        vertical-align: middle;
        border-style: none;
      }

      .arrow {
        transform: rotate(90deg);
        margin: -25px auto;
        float: unset;
        display: block;
      }

      .box {
        display: block;
        position: relative;
        float: unset;
        width: 100%;
        height: 100px;
        border: 1px solid #888;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-shadow: 1px 1px 1px #888;

        img {
          position: absolute;
          left: 20px;
          top: 20px;
          width: 40px;
          height: 40px;
        }

        h1 {
          position: absolute;
          left: 20px;
          top: 50px;
          line-height: 60px;
          font-weight: bold;
          font-size: 18px;
          color: #000;
          text-align: left;
        }

        h2 {
          position: unset;
          display: block;
          width: calc(100% - 80px);
          height: 100%;
          margin: 20px 0 auto 60px;
          line-height: 20px;
          color: #444;
          font-size: 16px;
          padding-left: 20px;
          padding-right: 20px;
          font-weight: normal;
        }
      }
    }
  }

  .txt-box {
    padding: 24px 16px;

    .txt1 {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    .txt2 {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }

    .btn {
      width: 100px;
      height: 22px;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .cardo-txt {
    display: block;
    height: 100%;
    padding: 24px 16px;
    text-align: center;

    .logo-w {
      display: inline-block;
      width: 140px;
      height: 24px;
      margin: 0 0 24px 0;
      padding: 0 0;
      font-size: 0;
      background-size: 100% 100%;
    }
  }
}
