@charset "utf-8";
@import '@style/mixin';

.content-split {
  @include flex;
  width: 100%;
  padding: 100px 0 80px;
  justify-content: space-between;

  > .content-info {
    margin: auto 0;
    .content-logo {
      @include width-height(152px, 48px);
      @include background(url('../../../images/logo/logo.png'), 100%, 100%, 0, 0, no-repeat);
      margin: 0 0 16px 0;
    }

    .content-title {
      color: #222;
      font-size: 50px;
      line-height: 58px;
      font-style: normal;
      font-weight: 700;
    }

    .content-subtitle {
      margin: 0 0 16px 0;
      color: #222;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
    }

    .content-text {
      color: #888;
      font-size: 20px;
      line-height: 32px;
      font-style: normal;
      font-weight: 400;
    }
  }
  > .content-image-box {
    @include flex;
    @include justify-content(space-between);
    margin: auto 0;
  }
}

@include tablet {
  .content-split {
    :nth-child(1) {
      margin-left: 20px;
      margin-right: 0;
    }
    :nth-last-child(1) {
      margin-left: 0;
      margin-right: 20px;
    }
    > .content-info {
      //margin: auto 20px;
      .content-logo {
        @include width-height(calc(152px * 0.75), calc(48px * 0.75));
      }
      .content-title {
        font-size: calc(50px * 0.75);
        line-height: calc(58px * 0.75);
      }
      .content-text {
        margin-right: 0;
      }
    }
    > .content-image-box {
      width: 40%;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
@include mobile {
  .content-split {
    display: block;
    margin: 0;
    padding: 20px 10px;
    > .content-info {
      width: 100%;
      margin: 10px auto;
      text-align: center;
      .content-title {
        font-size: 28px;
        line-height: 40px;
        br {
          display: none;
        }
      }
      .content-text {
        font-size: 14px;
        line-height: 20px;
      }
      .content-logo {
        margin: 0 auto;
        @include width-height(calc(152px * 0.6), calc(48px * 0.6));
      }
    }
    > .content-image-box {
      margin: 10px auto;
    }
  }
}
