@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: main.scss
* description	: main CSS import
* author		: kurt
* date			: 2024.02.06
+ version		: v1.0
************************************************************************/

.main-door {
  width: 100%;
  height: 600px;
  @include background(url('../../../images/common/background1.jpg'), auto, auto, center, center, no-repeat);
  background-color: #232323;
  object-fit: cover;

  .door-box {
    margin: 148px 0 0 0;
    color: #fff;

    .tit {
      margin: 0 0 16px 0;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px;
      letter-spacing: -0.3px;
    }

    .txt {
      margin: 0 0 80px 0;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
    }

    .btn {
      display: flex;
      width: 200px;
      height: 40px;
      //color: #ccc;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      align-items: center;
      border-radius: 100px;
      //border: 1px solid rgba(255, 255, 255, 0.1);
      //background-color: rgba(255, 255, 255, 0.1);
      background-color: #eee;
      backdrop-filter: blur(8px);
      //@include background(url('../../../SwapRight.png'), 20px, 20px, 121px, 8px, no-repeat);
      cursor: pointer;
      transition: background-color 0.5s;
      -webkit-transition: background-color 0.5s;

      a {
        padding: 8px 0 8px 0;
        color: #000;
      }

      &:hover {
        background: #1d8de4;
      }

      a:hover {
        color: #fff;
      }
    }
  }
}

.cardo-noti {
  @include flex;
  @include align-items(center);
  @include justify-content(flex-start);
  width: 100%;
  height: 80px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 22px;

  span {
    font-weight: 500;
  }

  .logo-w {
    height: 22px;
    @include background(url('../../../images/logo/logo.png'), 148px, 100%, 0, 0, no-repeat);
    padding: 0 0 0 210px;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
  }

  .noti-tit {
    height: auto;
    padding: 0 0 0 0;
    margin: 0 100px 0 0;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .noti {
    margin: 8px 70px 0 0;
    justify-content: flex-start;

    li {
      margin: 0 0 10px 0;
      // list-style: disc;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
    }

    a {
      justify-content: flex-start;
      color: #fff;

      &:hover {
        background-color: #6d6d6d;
      }
    }
  }
}

.main-info {
  @include flex;
  width: 100%;
  padding: 100px 0 80px;
  justify-content: space-between;

  &.info2 {
    padding: 30px 0;
  }

  .tit-img-box {
    .tit {
      margin: 0 0 16px 0;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    .hot-img {
      position: relative;
      @include width-height(600px, 400px);
      @include background(url('../../../images/main/custody_screenshot.png'), 100%, 100%, 0, 0, no-repeat);

      border: 1px solid #ddd;
      border-radius: 10px;
      //&::after {
      //  content: ' ';
      //  left: 0;
      //  position: absolute;
      //  border: 1px solid #ddd;
      //  border-radius: 10px;
      //  @include width-height(600px, 400px);
      //}
    }

    .m-img {
      @include width-height(604px, 540px);
      //@include background(url('../../../images/main/solution_img.png'), cover, cover, null, 0, no-repeat);
      background-image: url('../../../images/main/solution_img.png');
      background-size: cover;
      background-position-x: 50%;
      background-repeat: no-repeat;
    }

    .cert-box {
      margin-top: 20px;
      display: inline-block;
      text-align: center;
      vertical-align: bottom;
      color: #007aff;

      .img-box {
        border: solid 1px #ccc;
        border-radius: 20px;
        margin: 5px 5px 15px 5px;
      }

      .isms {
        @include width-height(200px, 200px);
        @include background(url('../../../images/certification/isms.png'), 90%, 90%, 50%, -50%, no-repeat);
        .isms-additional {
          color: black;
          font-size: 0.7rem;
          margin-top: 150px;
        }
      }

      .fiu {
        @include width-height(200px, 200px);
        @include background(url('../../../images/certification/fiu.svg'), 90%, 90%, 50%, 50%, no-repeat);
      }

      .soc {
        @include width-height(200px, 200px);
        @include background(url('../../../images/certification/soc.png'), 90%, 52%, 50%, 50%, no-repeat);
      }
    }

    .insurance-img {
      @include width-height(300px, 400px);
      @include background(url('../../../images/main/insurance.png'), 100%, 100%, 0, 0, no-repeat);
      border: 1px solid #ddd;
      border-radius: 10px;
      margin-right: 150px;
    }
  }

  .custody-info {
    .sub-logo {
      @include width-height(152px, 48px);
      @include background(url('../../../images/logo/logo.png'), 100%, 100%, 0, 0, no-repeat);
      margin: 50px 0 16px 0;
    }

    .logo-box {
      .sub-logo,
      .insurance-logo {
        display: inline-block;
      }
    }

    .tit {
      margin: 0 0 16px 0;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 68px;
    }

    .txt {
      color: #888;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
    }

    .btn {
      display: flex;
      @include width-height(200px, 40px);
      margin: 60px 0 0 0;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      border-radius: 100px;
      background: #0569ff;
      backdrop-filter: blur(8px);
      @include background(url('../../../images/common/swap_right.png'), 20px, 20px, 121px, 8px, no-repeat);
      cursor: pointer;

      a {
        padding: 8px 12px 8px 0;
        color: #fff;
      }
    }

    &.info2 {
      margin: 74px 0 0 0;
    }

    &.info3 {
      margin: 64px 0 0 190px;

      .txt {
        color: #4974b5;
      }
    }

    &.info4 {
      margin: 64px 130px 0 0;
    }

    &.info6 {
      margin: -50px 0 0 190px;
    }
  }
}

.main-contents {
  .section-full:nth-child(odd) {
    .main-info {
      .custody-info {
        width: 486px;
      }

      .tit-img-box {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  .section-full:nth-child(even) {
    background-color: #ddebff;

    .main-info {
      .custody-info {
        width: 490px;
        margin-left: 190px;
      }

      .tit-img-box {
        //padding-right: 100px;
      }
    }
  }

  .pt-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .pt-60 {
    padding-top: 60px !important;
  }

  .pr-150 {
    padding-right: 150px;
  }
}

// tablet css
@include tablet {
  .main-door {
    height: 375px;
    background-position: 68% 100%;

    .door-box {
      margin: 0 auto 0;
      padding: 111px 0 0;
      text-align: center;

      .tit {
        font-size: 34px;
        line-height: 42px;
      }

      .txt {
        margin: 0 0 40px 0;
        font-size: 26px;
        line-height: 34px;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }
  .cardo-noti {
    .noti-tit {
      width: 100px;
      margin: 0 10px;
    }

    .noti {
      max-width: calc((100% - 120px) / 3);

      margin: 0 10px;

      li {
        margin: 10px auto;
      }

      a {
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .main-info {
    width: 100%;
    // flex-direction: column;
    padding: 24px 16px;

    .tit-img-box {
      text-align: center;

      .tit {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .bit-img {
        width: 403px;
        height: 250px;
        margin: 0 auto;
      }

      .crypto-img {
        width: 343px;
        height: 270px;
        margin: 0 auto;
      }

      .hot-img {
        @include width-height(383px, 230px);
        margin: 30px auto 0;
      }

      .m-img {
        width: 300px;
        height: 406px;
        margin: 0 auto;
      }

      .m2-img {
        width: 443px;
        height: 406px;
        margin: 0 auto;
      }

      .isms-img {
        width: 343px;
        height: 180px;
        margin: 50px auto 0;
      }

      .cert-box {
        font-size: 0.7em;

        .isms,
        .fiu,
        .soc {
          @include width-height(133px, 133px);
        }
        .isms .isms-additional {
          color: black;
          font-size: 0.45rem;
          margin-top: 100px;
        }
      }
    }

    .custody-info {
      margin: 0 auto;
      text-align: center;

      .sub-logo {
        width: 133px;
        height: 42px;
        margin: 44px auto 8px;
      }

      .tit {
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
      }

      .txt {
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px;
      }

      .btn {
        margin: 24px auto;
        font-size: 14px;
        line-height: 24px;
      }

      &.info3,
      &.info4,
      &.info6 {
        width: 100%;
        margin: 24px auto;
      }
    }

    &.info2 {
      padding: 0 0;
    }
  }

  .main-contents {
    .section-full:nth-child(odd) {
      .main-info {
        .custody-info {
        }

        .tit-img-box {
          margin: 0 10px 0 auto;
          padding-left: unset;
          padding-right: unset;
        }
      }
    }

    .section-full:nth-child(even) {
      .main-info {
        .custody-info {
          min-width: 50vw;
          margin-left: unset;
        }

        .tit-img-box {
          margin: 0 auto 0 10px;
          .insurance-img {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

// mobile css
@include mobile {
  .main-door {
    // height: 375px;
    // background-position: 68% 100%;
    @include background(url('../../../images/common/background1.jpg'), auto, auto, center, center, no-repeat);

    .door-box {
      margin: 0 auto 0;
      padding: 111px 0 0;
      text-align: center;

      .tit {
        font-size: 24px;
        line-height: 32px;
      }

      .txt {
        margin: 0 0 40px 0;
        font-size: 16px;
        line-height: 24px;
      }

      .btn {
        margin: 0 auto;
      }
    }
  }
  .cardo-noti {
    display: block;
    height: auto;
    padding: 16px 16px;

    .logo-w {
      display: none;
    }

    .noti-tit {
      font-size: 16px;
    }

    .noti {
      margin: 0 0;
      max-width: unset;

      li {
        margin: 0 0 5px;
      }

      &:nth-child(2) {
        margin: 0 0 5px;
      }
    }
  }
  .main-info {
    width: 100%;
    flex-direction: column;
    padding: 24px 16px;

    .tit-img-box {
      .tit {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .bit-img {
        width: 100%;
        // max-width: 343px;
        max-width: 300px;
        height: 190px;
        margin: 0 auto;
      }

      .crypto-img {
        width: 100%;
        // max-width: 343px;
        max-width: 300px;
        // height: 270px;
        height: 200px;
        margin: 0 auto;
      }

      .hot-img {
        // max-width: 343px;
        max-width: 300px;
        width: 100%;
        // height: 190px;
        height: 150px;
        margin: 0 auto;
      }

      .m-img {
        // width: 343px;
        width: 300px;
        height: 286px;
        // height: 306px;
        margin: 0 auto;
      }

      .m2-img {
        // width: 343px;
        width: 300px;
        height: 286px;
        // height: 306px;
        margin: 0 auto;
      }

      .isms-img {
        // width: 343px;
        width: 300px;
        height: 160px;
        // height: 180px;
        margin: 0 auto 40px;
      }
    }

    .custody-info {
      margin: 0 auto;
      text-align: center;

      .sub-logo {
        width: 100px;
        height: 32px;
        margin: 24px auto 8px;
      }

      .tit {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .txt {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }

      .btn {
        margin: 24px auto;
        font-size: 14px;
        line-height: 24px;
      }

      &.info3,
      &.info6 {
        width: 100%;
        margin: 24px auto;
      }

      &.info4 {
        width: 100%;
        margin: 0 auto 0;
      }
    }

    &.info2 {
      padding: 20px 0;
    }
  }

  .main-contents {
    .section-full:nth-child(odd) {
      .main-info {
        .custody-info {
          margin: auto;
          width: 100%;
        }

        .tit-img-box {
          //margin: 0 10px 0 auto;
          width: 100%;
          margin: auto;
          padding-left: unset;
          padding-right: unset;
        }
      }
    }

    .section-full:nth-child(even) {
      .main-info {
        .custody-info {
          width: 100%;
          min-width: unset;
          margin: auto;
        }

        .tit-img-box {
          width: 100%;
          //margin: 0 auto 0 10px;
          .insurance-img {
            margin: auto;
          }
        }
      }
    }
    .pt-30 {
      padding: unset !important;
    }
    .main-info.pt-30 {
      padding: 24px 16px !important;
    }
  }
}
