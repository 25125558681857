@charset "utf-8";
@import '@style/mixin';

/************************************************************************
* filename		: company.scss
* description	: company CSS import
* author		: kurt
* date			: 2024.02.06
+ version		: v1.0
************************************************************************/

.company-door {
  @include background(url('../../../images/company/company_bg.png'), 100%, 100%, 0, 0, no-repeat);
}

.section-part {
  --sectionWidth: 1280px;

  .section-content {
    position: relative;
    display: inline-block;
    font-size: 1.1rem;

    .company-img {
      @include width-height(250px, 250px);
      display: block;
      border: solid 1px #ccc;
      border-radius: 20px;
      margin: 5px auto 15px;

      &.fiu {
        @include background(url('../../../images/certification/fiu.svg'), 100%, 100%, 0, 0, no-repeat);
      }

      &.isms {
        @include background(url('../../../images/certification/isms.png'), 100%, 100%, 0, 0, no-repeat);
      }

      &.soc {
        @include background(url('../../../images/certification/soc.png'), 90%, 52%, 50%, 50%, no-repeat);
      }
    }

    .title {
      color: #000;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      //line-height: 68px;
      line-height: 4rem;
      text-align: center;
    }

    .text {
      color: #888;
      font-weight: lighter;
      //font-size: 20px;
      font-style: normal;
      text-align: center;
    }

    button,
    a {
      @include width-height(200px, 50px);
      display: flex;
      //padding: 14px 8px;
      justify-content: center;
      align-items: center;
      color: #222;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      border-radius: 8px;
      border: 1px solid #ddd;
      background-color: #fff;
      cursor: pointer;
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      margin: 20px auto 0;

      &:hover {
        background-color: #1d8de4;
        color: #fff;
        border-color: #007aff;
      }
    }
  }
}

.his-tit {
  margin: 0 0 10px 0;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;

  span {
    font-weight: 700;
  }
}

.history-header {
  position: relative;
  @include flex;
  @include justify-content(space-between);
  margin: 0 0 30px 0;

  span {
    font-size: 2rem;
    font-weight: 700;
    margin: auto;

    &.logo-kdac {
      @include width-height(282px, 58px);
      background-image: url('../../../images/logo/logo.png');
      background-size: contain;
      background-position: center 0;
      background-repeat: no-repeat;
    }

    &.logo-cardo {
      @include width-height(282px, 48px);
      background-image: url('../../../images/logo/logo_cardo.png');
      background-size: contain;
      background-position: center 0;
      background-repeat: no-repeat;
    }
  }
}

.history {
  position: relative;
  padding-top: 40px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin: 0 0 0 -1px;
    background-color: #000;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0 0 0 -12px;
    //@include width-height(24px, 32px);
    //@include background(url('../../../logo_b.png'), 100%, 100%, 0, 0, no-repeat);
    background-color: #fff;
  }

  .history-end {
    position: relative;
    @include flex;
    justify-content: center;
    flex-direction: row;

    .text-box {
      @include flex;
      margin: auto;
      width: 400px;
      height: 50px;
      text-align: center;
      background-color: #007aff;
      border-radius: 10px;

      .txt {
        color: #fff;
        margin: auto auto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }

  .history-box {
    position: relative;
    @include flex;
    @include justify-content(space-between);
    margin: 0 0 50px 0;
    padding: 50px 0;

    .img-box {
      width: 587px;
    }

    .history-year {
      @include width-height(24px, 24px);
      content: '';
      display: block;
      position: absolute;
      right: calc(50% - 25px);
      top: 50%;
      margin: -50px -5px 0 0;
      width: 60px;
      height: 50px;
      text-align: center;
      background-color: #fff;

      &::after {
        content: '';
        display: block;
        margin: auto;
        @include width-height(24px, 24px);
        @include background(url('../../../images/company/point.png'), 100%, 100%, 0, 0, no-repeat);
      }

      span {
        display: block;
        margin: auto;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .his-info {
      position: relative;
      width: 587px;
      margin: auto;
      //padding: 112px 0 0 0;
      .year {
        margin: 0 0 16px 0;
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .txt {
        color: #0569ff;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 40px;
        margin-left: 40px;

        li {
          list-style: disc;
          margin: 0 0 0 20px;
        }

        //&::before {
        //  content: '';
        //  display: block;
        //  position: absolute;
        //  left: -65px;
        //  top: 50%;
        //  margin: -12px 0 0 0;
        //  @include width-height(24px, 24px);
        //  @include background(url('../../../Point.png'), 100%, 100%, 0, 0, no-repeat);
        //}
      }

      &.left {
        text-align: right;

        .txt {
          margin-right: 60px;

          li {
            position: relative;
            list-style: none;
            margin: 0 0 0 20px;
            text-align: right;

            &::before {
              content: '';
              display: block;
              position: absolute;
              right: -24px;
              top: 16px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #0569ff;
            }
          }

          //&::before {
          //  content: '';
          //  display: block;
          //  position: absolute;
          //  left: initial;
          //  right: -65px;
          //  top: 50%;
          //  margin: -12px 0 0 0;
          //  @include width-height(24px, 24px);
          //  @include background(url('../../../Point.png'), 100%, 100%, 0, 0, no-repeat);
          //}
        }
      }
    }
  }

  .start {
    position: absolute;
    left: 50%;
    top: 0;
    width: 0;
    height: 0;
    margin: 0 0 0 -10px;
    border-color: #000;
    border-top: 20px solid #000;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    //background-color: #000;
  }

  .end {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: 0 0 0 -5px;
    border-radius: 50%;
    background-color: #000;
  }
}

.company-logo {
  @include flex;
  @include justify-content(center);

  span {
    @include width-height(308px, 160px);
    margin: 0 15px 15px 0;

    //&:nth-child {
    //  margin-right: 5px;
    //}

    &.logo {
      border: solid 1px #ddd;
      border-radius: 10px;

      &.logo-nh {
        @include width-height(320px, 80px);
        @include background(url('../../../images/logo/logo_nh.jpg'), contain, null, center, center, no-repeat);
      }

      &.logo-sh {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_shinhanbank.png'), 90%, null, center, center, no-repeat);
      }

      &.logo-hexlant {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_hexlant.svg'), 90%, null, center, center, no-repeat);
      }

      &.logo-korbit {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_korbit.svg'), 80%, null, center, center, no-repeat);
      }

      &.logo-fair2lab {
        @include width-height(80px, 80px);
        @include background(url('../../../images/logo/logo_fair2lab.png'), 90%, null, center, center, no-repeat);
      }

      &.logo-galaxia {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_galaxia.png'), 70%, 95%, center, center, no-repeat);
      }

      &.logo-kica {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_kica.png'), 100%, null, center, center, no-repeat);
      }

      &.logo-kicc {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_kicc.jpg'), 70%, null, center, center, no-repeat);
      }

      &.logo-aton {
        @include width-height(220px, 80px);
        @include background(url('../../../images/logo/logo_aton.png'), 90%, null, center, center, no-repeat);
      }
    }

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.map {
  @include width-height(1280px, 600px);
  border: 2px solid #ddd;
}

.mobile-br {
  display: none;
}

// tablet css
@include tablet {
  .section-part {
    .section-content {
      .company-img {
        @include width-height(200px, 200px);
      }
    }
  }

  .his-tit {
    font-size: 30px;
    line-height: 38px;
  }

  .history {
    .history-box {
      margin-bottom: 0;

      .his-info {
        .txt {
          padding: 0;
          text-align: left;
          font-size: 18px;

          &::before {
            left: 38px;
            font-size: 18px;
          }
        }

        &.left {
          .txt {
            &::before {
              left: inherit;
              right: 38px;
              top: 105px;
            }

            li {
              //text-align: right;
              margin-left: 0;

              &::before {
                width: 6px;
                height: 6px;
                left: inherit;
                right: -15px;
              }
            }
          }
        }
      }
    }
  }

  .history-header {
    span {
      &.logo-kdac {
        @include width-height(211.5px, 43.5px);
      }

      &.logo-cardo {
        @include width-height(211.5px, 36px);
      }
    }
  }

  .company-logo {
    display: block;
    margin: 0 0;
    padding: 0 16px;
    text-align: center;

    &.holder-company span {
      display: inline-block;
      float: initial;
      width: 204px;
      height: 128px;
      margin: 0 3px 0;
      border-radius: 5px 5px;
      border: 1px solid #ddd;

      &.logo {
        @include width-height(calc(220px * 0.75), calc(80px * 0.75));
        //background-size: 244px 160px;
        //background-position: center;
        // background-size: cover;
        &.logo-nh {
          width: calc(320px * 0.75);
        }

        &.logo-fair2lab {
          width: calc(80px * 0.75);
        }
      }

      //&:nth-child(5) {
      //  margin: 0 0 0 0;
      //}
    }

    span {
      display: inline-block;
      float: initial;
      width: 204px;
      height: 128px;
      margin: 0 3px 0;
      border-radius: 5px 5px;
      border: 1px solid #ddd;
    }
  }

  .map {
    padding: 0 16px;
    width: 100%;
    height: 475px;
    margin: 0 auto;
  }
}

// mobile css
@include mobile {
  .section-part {
    .section-content {
      .company-img {
        @include width-height(150px, 150px);
      }
    }
  }

  .his-tit {
    font-size: 20px;
    line-height: 28px;
  }

  .history {
    .history-end {
      .text-box {
        width: 250px;
        height: 32px;

        .txt {
          font-size: 16px;
        }
      }
    }

    .history-box {
      margin: 0;

      .img-box {
        display: none;
      }

      .his-info {
        width: 100%;

        .txt {
          text-align: left;
          font-size: 12px;
          margin-left: 18px;
          margin-right: 10px;

          li {
            line-height: 20px;
          }

          &::before {
            left: 38px;
          }
        }

        &.left {
          .txt {
            margin-left: 10px;
            margin-right: 40px;

            &::before {
              left: inherit;
              right: 38px;
              top: 105px;
            }

            li {
              text-align: right;

              &::before {
                left: inherit;
                right: -15px;
                top: calc(50% - 2px);
                width: 4px;
                height: 4px;
              }
            }
          }
        }
      }
    }
  }

  .history-header {
    span {
      &.logo-kdac {
        @include width-height(169.2px, 34.8px);
      }

      &.logo-cardo {
        @include width-height(169.2px, 28.8px);
      }
    }
  }
  .company-logo {
    display: block;
    margin: 0 0;
    padding: 0 16px;
    text-align: center;

    span {
      display: inline-block;
      float: initial;
      width: 124px;
      height: 120px;
      margin: 0 auto 0;
      border-radius: 5px 5px;
      border: 0;

      &:nth-child {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .map {
    padding: 0 16px;
    width: 100%;
    height: 275px;
    margin: 0 auto;
  }

  .mobile-br {
    display: block;
  }
}
